<template>
  <div class="list-info">
    <top-bar :title="'问题列表'" :left="true"></top-bar>
    <div class="search">
      <input v-model="searchValue" type="search" value="搜索" placeholder="请输入提问者姓名搜索" class="search-content"
             @keyup.enter="onSearch">
      <img :src="require('@/assets/img/clear.png')" class="search-clear" @click="onCancel">
      <div class="search-btn" @click="onSearch">搜索</div>
    </div>
    <div class="filterBar">
      <van-row>
        <van-col span="6">
          <p @click="dateBarShow = !dateBarShow" :style="{color:dateBarShow?'#387FF5':'#666666' }">提问时间
            <img :src="require(`@/assets/img/${dateBarShow?'pull-down-select':'pull-down'}.png`)" alt=""
                 class="pull-icon">
          </p>
        </van-col>
        <van-col span="6">
          <p @click="rangeShow = !rangeShow" :style="{color:rangeShow?'#387FF5':'#666666' }">显示范围
            <img :src="require(`@/assets/img/${rangeShow?'pull-down-select':'pull-down'}.png`)" alt=""
                 class="pull-icon">
          </p>
        </van-col>
        <van-col span="6">
          <p @click="typeShow = !typeShow" :style="{color:typeShow?'#387FF5':'#666666' }">问题类型
            <img :src="require(`@/assets/img/${typeShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
        <van-col span="6">
          <p @click="moreShow = !moreShow" :style="{color:moreShow?'#387FF5':'#666666' }">更多
            <img :src="require(`@/assets/img/${moreShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
      </van-row>
    </div>
    <div class="dateBar" v-show="dateBarShow">
      <van-row>
        <van-col span="8" @click="beginDateShow = !beginDateShow">
          <span>{{ selectDate.beginDate == '' ? '最早' : selectDate.beginDate }}</span></van-col>
        <van-col span="2"><span>-</span></van-col>
        <van-col span="8" @click="endDateShow = !endDateShow">
          <span>{{ selectDate.endDate == '' ? '至今' : selectDate.endDate }}</span></van-col>
        <van-col span="3" @click="dateClose"><span>重置</span></van-col>
        <van-col span="3" @click="changeDate"><span>确定</span></van-col>
      </van-row>
    </div>
    <div class="add-box" @click="goAdd">
      <div class="add-img">
        <img class="add-icon" :src="require('@/assets/img/add.png')" alt="">
        <div class="text">新增、添加</div>
      </div>
    </div>
    <van-popup v-model="beginDateShow" position="bottom">
      <van-datetime-picker @confirm="beginDateConfim" @cancel="beginDateShow = false" type="date" title="选择年月日"
                           :min-date="new Date(2017, 0, 1)" :max-date="new Date()"/>
    </van-popup>
    <van-popup v-model="endDateShow" position="bottom">
      <van-datetime-picker @confirm="endDateConfim" @cancel="endDateShow = false" type="date" title="选择年月日"
                           :min-date="new Date(2017, 0, 1)" :max-date="new Date(2025, 10, 1)"/>
    </van-popup>
    <van-popup v-model="typeShow" position="bottom">
      <van-picker title="问题类型" show-toolbar :columns="typeList" value-key="label" @confirm="typeConfirm"
                  @cancel="typeShow = false"/>
    </van-popup>
    <van-popup v-model="rangeShow" position="bottom">
      <van-picker title="显示范围" show-toolbar :columns="rangeList" value-key="label" @confirm="rangeconfirm"
                  @cancel="rangeShow = false"/>
    </van-popup>
    <van-popup v-model="moreShow" position="right" :style="{ width: '90%', height: '100%'}" @open="moreClose">
      <van-cell title="问题类型">
        <span style="color: #387ff5">{{ moreTypeValue }}</span>
      </van-cell>
      <van-grid :border="false" :column-num="3">
        <van-grid-item v-for="value in typeList" :key="value.value">
          <van-tag size="large" @click="typeTagFn(value)"
                   :class="value.label === moreTypeValue?'checkedTag':'commonTag'">{{ value.label }}
          </van-tag>
        </van-grid-item>
      </van-grid>
      <van-cell title="处理状态">
        <span style="color: #387ff5">{{ moreStatusValue.label }}</span>
      </van-cell>
      <van-grid :border="false" :column-num="3">
        <van-grid-item v-for="item in statusList" :key="item.value">
          <van-tag size="large" @click="statusTagFn(item)"
                   :class="item.value == moreStatusValue.value?'checkedTag':'commonTag'">{{ item.label }}
          </van-tag>
        </van-grid-item>
      </van-grid>
      <van-row style="bottom: 0; left: 0; width: 100%">
        <van-col span="12">
          <van-button block @click="moreCancel">重置</van-button>
        </van-col>
        <van-col span="12">
          <van-button type="info" block @click="moreConfirm">确定</van-button>
        </van-col>
      </van-row>
    </van-popup>
    <div class="cont">
      <!-- <van-pull-refresh v-model="pullLoading" success-text="刷新成功" @refresh="onPullDownRefresh"> -->
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList"
                  v-if="dataList.length > 0" offset="10">
          <div class="totalCount">共有&nbsp;<span style="color:#387FF5">{{ totalCount }}</span>&nbsp;条数据</div>
          <div class="list-item" v-for="item in dataList" :key="item.id"
               @click="getInfo(item.id,item.status,item.streetIsOpen)">
            <div class="title">
              <div class="title-left">
                {{ item.name + ' | ' + item.createDate }}
              </div>
              <div class="title-right" :style="{color:item.status==0||item.status==10?'#ED4747':item.status==20?'#23D87B'
                :item.status==30?'#387FF5':'#0AB9B3'}">
                {{ item.statusStr }}
              </div>
            </div>
            <div class="content">
              <van-image :src="item.url?item.url:require('@/assets/img/flower.png')" class="content-img">
                <template slot="error">
                  <img :src="require('@/assets/img/flower.png')" alt="" class="content-img">
                </template>
                <template slot="loading">
                  <img :src="require('@/assets/img/flower.png')" alt="" class="content-img">
                </template>
              </van-image>
              <div class="content-text">
                <div class="top-text">{{ item.houseFullName }} <span
                    style="color:#387FF5;font-size:14px">({{ item.typeStr }})</span></div>
                <div class="bottom-text">{{ item.content }}</div>
              </div>
              <van-icon name="arrow" color="#666" class="content-icon"/>
            </div>
            <div style="width: 100%;height: 45px;">
              <button
                  class="item_btn"
                  style="background-color: rgba(56, 127, 245, .1); color: #387FF5;"
                  v-if="item.streetIsOpen == 1 && item.reportedType == 2 && item.problemSolveFiles && item.problemSolveFiles.length > 0"
                  @click.stop="goVerify(item.id)">核实
              </button>
              <button
                  class="item_btn"
                  style="background-color: rgba(56, 127, 245, .1); color: #387FF5;"
                  v-if="item.streetIsOpen == 1 && item.reportedId == 0 && item.problemSolveFiles && item.problemSolveFiles.length > 0"
                  @click.stop="goFour(item.id)">转四平台
              </button>

              <button
                  class="item_btn"
                  style="background-color: rgba(255, 169, 58, .1); color: #FFA93A;"
                  v-if="item.status == 10||item.status == 20"
                  @click.stop="handling(item.id,item.status, item.streetIsOpen)">处理
              </button>

              <button
                  class="item_btn"
                  style="background-color: rgba(237, 71, 71, .1); color: #ED4747;"
                  @click.stop="deleteHandle(item.id)">删除
              </button>
            </div>

          </div>
        </van-list>
        <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
      <!-- </van-pull-refresh> -->
    </div>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'

import {formatterDate} from '@/utils/utils'
import {getImageStream} from '@/utils/index'
import {mapMutations, mapState} from 'vuex'

export default {
  name: 'problem',
  components: {
    topBar
  },
  data() {
    return {
      pullLoading: false,
      loading: false,
      finished: false,
      rangeShow: false,
      endDateShow: false,
      beginDateShow: false,
      dateBarShow: false,
      typeShow: false,
      moreShow: false,
      totalCount: 0,
      page: 0,
      limit: 10,
      scope: '',
      currentDate: '',
      selectDate: {
        endDate: '',
        beginDate: ''
      },
      typeList: ['1', '2'],
      rangeList: [{value: "0", label: "所有"}, {value: "1", label: "我提问的"}],
      statusList: [
        {label: '无法处理', value: '0',},
        {label: '未处理', value: '10'},
        {label: '处理中', value: '20'},
        {label: '处理完成', value: '30'}],
      moreStatusValue: {label: '', value: ''},
      currentType: '',
      moreTypeValue: '',
      moreType: '',
      searchValue: '',
      dataList: [],
      houseId: null,
      problemIds: ''
    };
  },
  methods: {
    ...mapMutations,
    getInfo(id, status, streetIsOpen) {
      let data = {'id': id, 'status': status, 'streetIsOpen': streetIsOpen}
      this.$store.commit('setProblemData', data)
      this.$router.push('/problem-info')
    },
    goFour(id) {
      this.$router.push({path: '/problem-four', query: {id}})
    },
    goFinish(id) {
      this.$router.push({path: '/problem-finish', query: {id}})
    },
    goVerify(id) {
      this.$router.push({path: '/problem-verify', query: {id}})
    },
    handling(id, status, streetIsOpen) {
      let data = {'id': id, 'status': status, 'streetIsOpen': streetIsOpen}
      this.$store.commit('setProblemData', data)
      this.$router.push({path: '/problem-handling', query: {ids: this.problemIds}})
    },
    deleteHandle(id) {
      this.$dialog.confirm({
        title: '确定删除吗？'
      }).then(() => {
        this.$httpApp({
          url: this.$httpApp.adornUrl('/wxapp/problem/delete'),
          method: 'post',
          params: this.$httpApp.adornParams({
            id: parseInt(id)
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.$toast.success({
              message: '删除成功',
              duration: 3000,
            })
            this.dataList = []
            this.page = 0
            this.getDataList()
          } else {
            this.$toast.fail(data.msg)
          }
        })
      })
    },
    getDataList() {
      this.page++
      this.$toast.loading({duration: 0, message: '加载中...', forbidClick: true,});
      this.$httpApp({
        url: this.$httpApp.adornUrl('/wxapp/problem/list'),
        method: 'post',
        params: this.$httpApp.adornParams({
          page: this.page,          //当前页码
          limit: this.limit,        //每页展示的记录数
          name: this.searchValue, // 搜索框内容,以名字作为查询条件
          beginDate: this.selectDate.beginDate, //提问时间
          endDate: this.selectDate.endDate,
          scope: this.scope,
          type: this.currentType,          //问题类型
          status: this.moreStatusValue.value,      //处理状态
          source: '',
          solveTarget: '', //处理对象
          clientType: 2,
          houseId: this.houseId,
          ids: this.problemIds || undefined
        })
      }).then(({data}) => {
        if (data.code == 0) {
          this.pullLoading = false
          if (this.dataList.length == data.list.totalCount) {
            this.finished = true
          }
          data.list.list.forEach(item => {
            if (item.problemFiles && item.problemFiles.length != 0) {
              let file = item.problemFiles[0]
              let url = file.filePath
              if (url) {
                url = getImageStream(url)
                item["url"] = url
              }
            }
          })
          this.totalCount = data.list.totalCount
          this.dataList = this.dataList.concat(data.list.list)
          // 加载状态结束
          this.loading = false;
          this.$toast.clear()
        }
        this.$toast.clear()
      }, err => {
        this.$toast.clear()
      })
    },
    getProblemType() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/dict/listDictByCode'),
        method: 'post',
        params: this.$http.adornParams({
          orgId: this.$orgId,
          code: 'problemType'
        }, false)
      }).then(({data}) => {
        if (data.code == 0) {
          this.typeList = data.dicts
          this.typeList.unshift({label: '所有', value: ''})
        } else {
          this.$toast.fail(data.msg);
        }
      })
    },
    rangeconfirm(val) {
      this.scope = val.value
      this.page = 0
      this.dataList = []
      this.getDataList()
      this.rangeShow = false
    },
    beginDateConfim(value) {
      let d = new Date(this.selectDate.endDate)
      if (this.selectDate.endDate != '' && d.getTime(d) < value.getTime(value)) {
        return this.$toast.fail('开始时间不能比结束时间大哦')
      }
      this.selectDate.beginDate = formatterDate(value)
      this.beginDateShow = false
    },
    endDateConfim(value) {
      let d = new Date(this.selectDate.beginDate)
      if (this.selectDate.beginDate != '' && d.getTime(d) > value.getTime(value)) {
        return this.$toast.fail('结束时间不能比开始时间小哦')
      }
      this.selectDate.endDate = formatterDate(value)
      this.endDateShow = false
    },
    changeDate() {
      this.dataList = []
      this.page = 0
      this.getDataList()
    },
    dateClose() {
      this.selectDate = {
        endDate: '',
        beginDate: ''
      }
    },
    typeConfirm(value, index) {
      //debugger
      this.page = 0
      this.dataList = []
      this.currentType = value.value
      this.getDataList()
      this.typeShow = false
    },
    typeTagFn(val) {
      this.moreTypeValue = val.label
      this.moreType = val.value
    },
    moreCancel() {
      this.finished = false
      this.moreTypeValue = ''
      this.moreType = ''
      this.moreStatusValue.label = ''
      this.moreStatusValue.value = ''
    },
    moreConfirm() {
      this.dataList = []
      this.page = 0
      this.currentType = this.moreType
      this.getDataList()
      this.moreShow = false
    },
    moreClose() {
      this.typeList.forEach(item => {
        if (this.currentType === item.value) {
          this.moreTypeValue = item.label
        }
      })
    },
    statusTagFn(item) {
      this.moreStatusValue.label = item.label
      this.moreStatusValue.value = item.value
    },
    goAdd() {
      this.$router.push('/problem-add')
    },
    onPullDownRefresh(e) {
      this.finished = false
      this.dataList = []
      this.page = 0;
      this.getDataList()
    },
    onSearch() {
      this.page = 0
      this.dataList = []
      this.getDataList()
    },
    onCancel() {
      this.page = 0
      this.dataList = []
      this.searchValue = ''
      this.getDataList()
    },
  },
  created() {
    if (this.$route.query.houseId) {
      this.houseId = this.$route.query.houseId
    }
    if (this.$route.query.ids) {
      this.problemIds = this.$route.query.ids
    }
    this.$toast.loading({
      duration: 0,
      message: '加载中...',
      forbidClick: true,
    });
    this.getDataList()
    this.getProblemType()
  }
}
</script>

<style scoped>
.item_btn {
  float: right;
  font-weight: bold;
  border: none;
  text-align: center;
  line-height: 64px;
  padding: 0 40px;
  height: 64px;
  font-size: 24px;
  border-radius: 32px;
  margin-left: 20px;
}

.van-field__control {
  text-align: left !important;
}

.van-tag {
  text-align: center;
  display: block;
  width: 190px;
  height: 70px;
  line-height: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 10px;
}

.checkedTag {
  background-color: rgba(0, 122, 255, 0.1);
  color: #387ff5;
  border: 1px solid #387ff5;
}

.commonTag {
  background-color: #f0f2f5;
  color: #333;
}
</style>
